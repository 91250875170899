<template>
  <div class="vs-con-loading__container">
    <h2>Usuario</h2>
    <div class="box" v-if="!user.name">
      <h2>Usuario no encontrado</h2>
    </div>
    <div class="box vs-con-loading__container" v-if="user.name">
      
      <vs-input label-placeholder="Nombre" v-model="user.name" /><br>

      <vs-input label-placeholder="Email" v-model="user.email" /><br>

      <div v-if="!myself">
        <span class="nice-label">Nivel</span>
        <vs-select :options="levels" v-model="user.level">
          <vs-select-item
            :key="item.key"
            :value="item.value"
            :text="item.text"
            v-for="item in levels"
          />
        </vs-select><br>
      </div>
      
      <span class="nice-label">Empresa</span>
      <vs-select :options="tenants" v-model="user.currentTenant">
        <vs-select-item
          :key="item.key"
          :value="item.value"
          :text="item.text"
          v-for="item in tenants"
        />
      </vs-select>
    
    </div>
    <vs-row vs-justify="center" class="mt-20" v-if="user.name">
      <vs-button size="large" @click="btnLoader($event, save)">Guardar cambios</vs-button>
    </vs-row>
  </div>
</template>

<script>
/* eslint-disable */
import btnLoader from './btnLoader'
import { collection, db, setDoc, doc, getDoc, getDocs } from '../firebase'

export default {
  data() {
    return {
      user: { name: '.' },
      logoUploadData: {},
      tenantMode: '..',
      levels: [],
      myself: false,
      tenants: [],
    }
  },
  async mounted() {
    this.btnLoader = btnLoader(this);
    const tenants = (await getDocs(collection(db, "clients"))).docs;
    for (const tenant of tenants) {
      this.tenants.push({ value: tenant.id, text: tenant.data().name })
    }

    this.levels = [
      { value: 1, text: 'Usuario' },
      { value: 2, text: 'Admin' },
      { value: 5, text: 'Super Admin' },
    ];

    getDoc(doc(db, "access", this.$route.params.id)).then(doc => {
      this.user = {id:doc.id, ...doc.data()} || {};
      if (this.user.id === this.$store.state.user.id) {
        this.myself = true;
      }
    }).catch(err => {
      console.log(err);
    });
  },
  methods: {
    async save(stopLoader) {
      setDoc(doc(db, "access", this.$route.params.id), this.user).then(user => {
        stopLoader(true);
      }).catch(err => {
        console.log(err);
        alert('No se pudo guardar el usuario');
        stopLoader(true);
      });
    },
  },

}
</script>

<style>
.red,
.red-label .vs-input--placeholder {
  color: red !important;
}
.link {
  color: rgba(var(--vs-primary), 1) !important;
}
.user-logo {
  height: 32px !important;
  float: left;
  border-radius: 5px;
}
.logo-container {
  display: flex;
}
</style>